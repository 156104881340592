'use client'

import Image from "next/image"
import CenterLayout from "@/layout/CenterLayout"
import { handleSignIn } from "@/utils/auth"
import { images } from "@/utils/images"
import { mixpanelTrack, mixpanelTrackPageView } from "@/utils/mixpanel"
import { useEffect } from "react"

export default function LoginPage() {

  useEffect(() => {
    mixpanelTrackPageView('LoginPage')
  }, [])

  const KakaoTalkButton = () => {
    return (
      <div 
        className="flex gap-1 mt-10 rounded-[10px] bg-[#fee500] w-full h-12 items-center justify-center cursor-pointer sm:w-[360px] hover:bg-[#f6dd00] active:scale-[98%]"
        onClick={() => {
          mixpanelTrack('LoginPage_LoginButton', 'Clicked')
          handleSignIn()
        }}
      >
        <svg viewBox="0 0 21 20" width="21" height="21" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.5 2.62891C6.16282 2.62891 2.64282 5.36319 2.64282 8.72605C2.64282 10.8239 4.00211 12.6546 6.07639 13.7703L5.20425 16.9682C5.1878 17.0318 5.19118 17.0989 5.21396 17.1605C5.23673 17.2222 5.27781 17.2754 5.33167 17.313C5.38554 17.3506 5.44962 17.3709 5.51532 17.371C5.58102 17.3712 5.6452 17.3513 5.69925 17.3139L9.51782 14.776C9.83997 14.776 10.17 14.8311 10.5 14.8311C14.8371 14.8311 18.3571 12.0968 18.3571 8.72605C18.3571 5.35534 14.8371 2.62891 10.5 2.62891Z" fill="#181600">
          </path>
        </svg>
        <div className="text-sm font-semibold text-default-700">
          카카오 계정으로 계속하기
        </div>
      </div>
    )
  }

  return (
    <>
      <CenterLayout>
        <div className="flex flex-col w-full m-auto items-center gap-0 pt-32">
          <Image width={60} height={60} src={images.logo} className="mb-5" alt='company-logo'/>
          <div className="text-[24px] md:text-[28px] font-bold tracking-tight">쉽게 가입하고</div>
          <div className="text-[24px] md:text-[28px] font-bold tracking-tight">간편하게 로그인하세요</div>
          <KakaoTalkButton/>
        </div>
      </CenterLayout>
    </>
  )
}